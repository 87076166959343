import jQuery from "jquery";

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = jQuery.trim(cookies[i]);
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

let csrftoken = getCookie("csrftoken");

const CSRFToken = () => {
  return (
    <input
      type="hidden"
      name="csrfmiddlewaretoken"
      id="csrfmiddlewaretoken-some-id"
      value={csrftoken}
    />
  );
};
export default CSRFToken;
