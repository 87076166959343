import { useCallback, useEffect, useState } from "react";

import {
  getUserStations,
  listEmployees,
  listWashReservations,
} from "../api/backendCalls";
import ModalUpdateReservation from "../components/ModalUpdateReservation";
import AuthProvider from "../context/AuthProvider";
import useTitle from "../hooks/useTitle";
import WashReservationsTable from "../components/WashReservationsTable";

const Dashboard = ({ title, station, setStation }) => {
  useTitle({ title });

  const { getAuth } = AuthProvider();

  const [washReservations, setWashReservations] = useState(null);
  const [washReservationsNext3Days, setWashReservationsNext3Days] =
    useState(null);
  const [washReservation, setWashReservation] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [show, setShow] = useState(false);

  const handleModalOpen = (id) => {
    for (let i = 0; i < washReservations.length; i++) {
      if (washReservations[i].id === id) {
        setWashReservation(washReservations[i]);
        setShow(true);
        return;
      }
    }
    for (let i = 0; i < washReservationsNext3Days.length; i++) {
      if (washReservationsNext3Days[i].id === id) {
        setWashReservation(washReservationsNext3Days[i]);
        setShow(true);
        return;
      }
    }
  };

  useEffect(() => {
    const getData = async (station) => {
      let data = await getUserStations(getAuth());

      if (data === null) return;

      if (JSON.stringify(data) !== JSON.stringify(station)) {
        setStation(data);
      }
    };

    getData(station);
  }, [station, setStation, getAuth]);

  useEffect(() => {
    const getData = async (station_id) => {
      let data = await listEmployees(getAuth(), station_id);

      if (data === null) return;

      if (JSON.stringify(data) !== JSON.stringify(employees)) {
        setEmployees(data);
      }
    };

    if (station === null || typeof station.id !== "number") {
      return;
    }
    getData(station.id);
  }, [station, employees, setEmployees, getAuth]);

  const getWashReservations = useCallback(
    async (station, washReservations, when) => {
      if (
        station == null ||
        !("id" in station) ||
        typeof station.id !== "number"
      )
        return;

      let data = await listWashReservations(getAuth(), station.id, when);
      if (data === null) return;
      if (JSON.stringify(data) !== JSON.stringify(washReservations)) {
        if (when === "today") {
          setWashReservations(data);
        } else {
          setWashReservationsNext3Days(data);
        }
      }
    },
    [getAuth]
  );

  useEffect(() => {
    getWashReservations(station, washReservations, "today");
    getWashReservations(station, washReservationsNext3Days, "next-3-days");
  }, [
    show,
    station,
    washReservations,
    washReservationsNext3Days,
    getWashReservations,
  ]);

  useEffect(() => {
    getWashReservations(station, washReservations, "today");
    getWashReservations(station, washReservationsNext3Days, "next-3-days");
    const interval = setInterval(() => {
      getWashReservations(station, washReservations, "today");
      getWashReservations(station, washReservationsNext3Days, "next-3-days");
    }, 5000);
    return () => clearInterval(interval);
  }, [
    getWashReservations,
    station,
    washReservations,
    washReservationsNext3Days,
  ]);

  return (
    <section className="dashboard-page">
      <h2 className="page-title p-10px">
        Συνολικές κρατήσεις σήμερα:{" "}
        {washReservations !== null ? washReservations.length : ""}
      </h2>

      <WashReservationsTable
        washReservations={washReservations}
        handleModalOpen={handleModalOpen}
      />

      <br />
      <br />
      <h2 className="page-title p-10px">
        Συνολικές κρατήσεις τις επόμενες δέκα μέρες:{" "}
        {washReservationsNext3Days !== null
          ? washReservationsNext3Days.length
          : ""}
      </h2>

      <WashReservationsTable
        washReservations={washReservationsNext3Days}
        handleModalOpen={handleModalOpen}
      />

      <ModalUpdateReservation
        washReservation={washReservation}
        show={show}
        setShow={setShow}
        employees={employees}
      />
    </section>
  );
};

export default Dashboard;
