import {
  getDateAfterDays,
  getDatetimeFormattedForBackend,
} from "../utils/helper";
import axios from "./axios";

const urls = {
  checkAuthentication: "/validate_token",
  logout: "/logout",
  login: "/login",
  get_user_stations: "/get_user_stations",
  list_wash_reservations: "/list_wash_reservations",
  list_employees: "/list_employees",
  update_wash_reservation: "/update_wash_reservation",
};

const getUnAuthorizedHeadersWithCSRF = (csrfToken) => {
  return {
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    },
  };
};

const getAuthorizedHeaders = (token) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };
};

export const checkAuthentication = async (token) => {
  if (
    token === null ||
    !("accessToken" in token) ||
    token?.accessToken === null
  )
    return false;
  try {
    await axios.post(
      urls.checkAuthentication,
      JSON.stringify({
        role: "Gas_station_operator",
      }),
      getAuthorizedHeaders(token.accessToken)
    );
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const logoutUser = async (token) => {
  try {
    await axios.post(
      urls.logout,
      JSON.stringify({}),
      getAuthorizedHeaders(token.accessToken)
    );
    return [null, true];
  } catch (err) {
    if (!err?.response) {
      return ["No Server Response.", false];
    }
  }
  return ["Something went wrong.", false];
};

export const loginUser = async (user, pwd, csrfToken) => {
  try {
    const response = await axios.post(
      urls.login,
      JSON.stringify({ username: user, password: pwd }),
      getUnAuthorizedHeadersWithCSRF(csrfToken)
    );
    if (response && response.data && response.data.token)
      return [null, response.data.token];
  } catch (err) {
    console.log(err);
    if (!err?.response) {
      return ["No Server Response.", null];
    } else if (
      "username" in err.response.data ||
      "password" in err.response.data
    ) {
      return ["Missing Username or Password.", null];
    } else if ("non_field_errors" in err.response.data) {
      return ["Username and password do not match.", null];
    }
  }

  return ["Something went wrong. Please try again.", null];
};

export const getUserStations = async (token) => {
  try {
    let response = await axios.post(
      urls.get_user_stations,
      JSON.stringify({}),
      getAuthorizedHeaders(token.accessToken)
    );
    if (
      response &&
      response.data &&
      "stations" in response.data &&
      response.data["stations"].length > 0
    )
      return response.data["stations"][0];
  } catch (err) {
    console.log("error 101", err);
  }
  return null;
};

export const listWashReservations = async (token, station_id, when) => {
  let startAt, endAt;
  startAt = new Date();
  startAt.setHours(0, 0, 0, 0);
  if (when === "today") {
    endAt = getDateAfterDays(startAt, 1);
  } else if (when === "next-3-days") {
    startAt = getDateAfterDays(startAt, 1);
    endAt = getDateAfterDays(startAt, 11);
  }

  try {
    let response = await axios.post(
      urls.list_wash_reservations,
      JSON.stringify({
        station_id: station_id,
        start_at: getDatetimeFormattedForBackend(startAt),
        end_at: getDatetimeFormattedForBackend(endAt),
      }),
      getAuthorizedHeaders(token.accessToken)
    );
    if (response && response.data) {
      response.data.sort((a, b) => {
        var keyA = a.start_at,
          keyB = b.start_at;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      return response.data;
    }
  } catch (err) {
    console.log("error 102", err);
  }
  return null;
};

export const listEmployees = async (token, station_id) => {
  try {
    let response = await axios.post(
      urls.list_employees,
      JSON.stringify({
        station_id: station_id,
      }),
      getAuthorizedHeaders(token.accessToken)
    );
    if (response && response.data) {
      return response.data;
    }
  } catch (err) {
    console.log("error 103", err);
  }
  return null;
};

export const updateWashReservation = async (token, obj) => {
  try {
    let response = await axios.post(
      urls.update_wash_reservation,
      JSON.stringify(obj),
      getAuthorizedHeaders(token.accessToken)
    );
    if (response && response.data) {
      return response.data;
    }
  } catch (err) {
    console.log("error 104", err);
  }
  return null;
};
