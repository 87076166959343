import { stateMapper } from "../api/mappers";
import { getDatetimeFormattedForFrontend } from "../utils/helper";

const WashReservationsTable = ({ washReservations, handleModalOpen }) => {
  return (
    <table className="wash-reservations-table">
      <thead>
        <tr>
          <th>Ώρα ραντεβού</th>
          <th>Ονοματεπώνυμο</th>
          <th>Είδος πλυσίματος</th>
          <th>Τιμή</th>
          <th>Κατάσταση</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {washReservations !== null
          ? washReservations.map((reservation) => {
              return (
                <tr key={reservation.id}>
                  <td>
                    {getDatetimeFormattedForFrontend(
                      new Date(reservation.start_at)
                    )}
                  </td>
                  <td>{reservation.name}</td>
                  <td>{reservation.wash_type.name}</td>
                  <td>{reservation.final_price} €</td>
                  <td className={"state-" + reservation.state}>
                    {reservation.state in stateMapper
                      ? stateMapper[reservation.state]
                      : ""}
                  </td>
                  <td>
                    {["Reserved", "Started"].includes(reservation.state) ? (
                      <button
                        className="blue-color transparent-button"
                        onClick={() => handleModalOpen(reservation.id)}
                      >
                        Επεξεργασία
                      </button>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
};

export default WashReservationsTable;
