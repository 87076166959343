import { useEffect } from "react";
import { Link } from "react-router-dom";

import { logoutUser } from "../api/backendCalls";
import AuthProvider from "../context/AuthProvider";
import useTitle from "../hooks/useTitle";

const Logout = ({ title }) => {
  useTitle({ title });

  const { logout, getAuth } = AuthProvider();

  useEffect(() => {
    const logoutFunction = async () => {
      await logoutUser(getAuth());
      logout();
    };

    logoutFunction();
  }, [logout, getAuth]);

  return (
    <section className="fccc">
      <h1 className="app-page-title">Έκανες επιτυχώς log out.</h1>
      <div className="logout-div fccc">
        <Link to="/login">Ξανασυνδέσου</Link>
      </div>
    </section>
  );
};

export default Logout;
