import "./components.css";
import { Link } from "react-router-dom";

const Header = ({ station }) => {
  return (
    <header className="header frcc w-100 h-80px">
      <div className="header-main frbc">
        <Link to="/">
          <img
            src={`${process.env.PUBLIC_URL}/favicon.ico`}
            alt="Μπαράτσας Καύσιμα"
          />
        </Link>
      </div>
      <div>
        <h2 className="header-h2 p-20px">{station.name}</h2>
      </div>
    </header>
  );
};

export default Header;
