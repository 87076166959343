import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../bootstrap.css";
import { useAlert } from "react-alert";
import ReactLoading from "react-loading";

import AuthProvider from "../context/AuthProvider";
import Select3 from "./Select3/Select";
import Select2 from "./Select2/Select";
import { stateMapper } from "../api/mappers";
import {
  getDatetimeFormattedForBackend,
  getDatetimeFormattedForFrontend,
  strToDate,
} from "../utils/helper";
import { updateWashReservation } from "../api/backendCalls";

const DATETIME_DEFAULT_VALUE = "wrong";

const ModalUpdateReservation = ({
  washReservation,
  show,
  setShow,
  employees,
}) => {
  const alert = useAlert();
  const { getAuth } = AuthProvider();

  // the following state variables are needed for error handling
  const [employee, setEmployee] = useState(null);
  const [washReservationState, setWashReservationState] = useState(null);
  const [startsAt, setStartsAt] = useState(DATETIME_DEFAULT_VALUE);
  const [endsAt, setEndsAt] = useState(DATETIME_DEFAULT_VALUE);

  const [employeeError, setEmployeeError] = useState(false);
  const [washReservationStateError, setWashReservationStateError] =
    useState(false);
  const [startsAtError, setStartsAtError] = useState(false);
  const [endsAtError, setEndsAtError] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (washReservation === null) return;

    if (washReservation.employee !== null) {
      setEmployee(washReservation.employee);
    }
  }, [washReservation]);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setStartsAtError(false);
  }, [startsAt]);

  useEffect(() => {
    setEndsAtError(false);
  }, [endsAt]);

  useEffect(() => {
    setWashReservationStateError(false);
  }, [washReservationState]);

  useEffect(() => {
    setEmployeeError(false);
  }, [employee]);

  const handleSubmit = async () => {
    setLoading(true);
    /* Basic checks */
    if (washReservationState === null) {
      setWashReservationStateError(true);
      setLoading(false);
      return;
    }
    if (employee === null) {
      setEmployeeError(true);
      setLoading(false);
      return;
    }
    /* End basic checks values */

    let updateObj = {};
    if (washReservationState.name === "Ακύρωση πλυσίματος") {
      updateObj = {
        id: washReservation.id,
        employee_id: employee.id,
        cancelled_at: getDatetimeFormattedForBackend(new Date()),
        state: "Canceled",
      };
    } else if (washReservationState.name === "Το πλύσιμο ξεκινάει") {
      if (startsAt === DATETIME_DEFAULT_VALUE) {
        setStartsAtError(true);
        setLoading(false);
        return;
      }
      updateObj = {
        id: washReservation.id,
        employee_id: employee.id,
        start_at: getDatetimeFormattedForBackend(strToDate(startsAt)),
        state: "Started",
      };
    } else if (washReservationState.name === "Το πλύσιμο ολοκληρώθηκε") {
      if (endsAt === DATETIME_DEFAULT_VALUE) {
        setEndsAtError(true);
        setLoading(false);
        return;
      }

      updateObj = {
        id: washReservation.id,
        employee_id: employee.id,
        end_at: getDatetimeFormattedForBackend(strToDate(endsAt)),
        state: "Finished",
      };
    } else {
      console.log("Something went wrong (1)...");
      return;
    }

    let data = updateWashReservation(getAuth(), updateObj);
    if (data === null) {
      setLoading(false);
      console.log("Something went wrong (2)...");
      return;
    }
    setLoading(false);
    setShow(false);
    setEmployee(null);
    setWashReservationState(null);
    setStartsAt(DATETIME_DEFAULT_VALUE);
    setEndsAt(DATETIME_DEFAULT_VALUE);

    alert.success("Επιτυχής ενημέρωση κράτησης");
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      dialogClassName="my-modal-dialog"
      contentClassName="my-modal-content"
    >
      <Modal.Header className="modal-header">
        {washReservation !== null ? (
          <h1 className="modal-h1">Αριθμός κράτησης #{washReservation.hash}</h1>
        ) : (
          <></>
        )}
      </Modal.Header>
      <Modal.Body>
        {washReservation !== null ? (
          <table className="modal-table">
            <tbody>
              <tr>
                <td className="modal-field-title">Κρατημένο για:</td>
                <td className="w-400px">
                  {getDatetimeFormattedForFrontend(
                    new Date(washReservation.start_at)
                  )}
                </td>
              </tr>
              <tr>
                <td className="modal-field-title">Όνομα:</td>
                <td>{washReservation.name}</td>
              </tr>
              <tr>
                <td className="modal-field-title">Τηλέφωνο:</td>
                <td>{washReservation.phone}</td>
              </tr>
              <tr>
                <td className="modal-field-title">Είδος πλυσίματος:</td>
                <td>{washReservation.wash_type.name}</td>
              </tr>
              <tr>
                <td className="modal-field-title">Τιμή:</td>
                <td>{washReservation.final_price} €</td>
              </tr>
              <tr>
                <td className="modal-field-title">Βενζινάδικο:</td>
                <td>{washReservation.station.name}</td>
              </tr>
              <tr>
                <td className="modal-field-title">Προωθήθηκε από:</td>
                <td>
                  {washReservation &&
                  "promoted_by_employee" in washReservation &&
                  washReservation.promoted_by_employee &&
                  "name" in washReservation.promoted_by_employee &&
                  "surname" in washReservation.promoted_by_employee
                    ? `${washReservation.promoted_by_employee.name} ${washReservation.promoted_by_employee.surname}`
                    : "-"}
                </td>
              </tr>
              <tr>
                <td className="modal-field-title">Σχόλια:</td>
                <td>
                  {washReservation &&
                  "comments" in washReservation &&
                  washReservation.comments !== ""
                    ? `${washReservation.comments}`
                    : "-"}
                </td>
              </tr>

              {washReservation.state === "Started" ? (
                <tr>
                  <td className="modal-field-title">
                    Το πλύσιμο ξεκίνησε στις:
                  </td>
                  <td>
                    {getDatetimeFormattedForFrontend(
                      new Date(washReservation.start_at)
                    )}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              <tr>
                <td className="modal-field-title">Κατάσταση κράτησης:</td>
                <td>{stateMapper[washReservation.state]}</td>
              </tr>
              <tr>
                <td className="modal-field-title">Υπεύθυνος για το πλύσιμο:</td>
                <td>
                  {employees !== null ? (
                    <>
                      <Select3
                        initialText={
                          employee !== null ? employee.name : "Επίλεξε"
                        }
                        options={employees !== null ? employees : []}
                        selected={employee}
                        setSelected={setEmployee}
                        width={200}
                        widthSelect={200}
                        tabIndex={1}
                        reset={[]}
                      />
                      {employeeError !== false ? (
                        <p className="error-p">Η επιλογή είναι υποχρεωτική</p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              <tr>
                <td className="modal-field-title">
                  Ενημέρωση κατάσταση κράτησης:
                </td>
                <td>
                  {washReservation.state === "Reserved" ? (
                    <>
                      <Select2
                        initialText={
                          washReservationState !== null
                            ? washReservationState.name
                            : "Επίλεξε"
                        }
                        options={[
                          {
                            name: "Το πλύσιμο ξεκινάει",
                            id: 1,
                          },
                          {
                            name: "Ακύρωση πλυσίματος",
                            id: 2,
                          },
                        ]}
                        selected={washReservationState}
                        setSelected={setWashReservationState}
                        width={200}
                        widthSelect={200}
                        tabIndex={2}
                        reset={[]}
                      />
                      {washReservationStateError !== false ? (
                        <p className="error-p">
                          Επίλεξε μία από τις διαθέσιμες επιλογές
                        </p>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : washReservation.state === "Started" ? (
                    <>
                      <Select2
                        initialText={
                          washReservationState !== null
                            ? washReservationState.name
                            : "Επίλεξε"
                        }
                        options={[
                          {
                            name: "Το πλύσιμο ολοκληρώθηκε",
                            id: 1,
                          },
                          {
                            name: "Ακύρωση πλυσίματος",
                            id: 2,
                          },
                        ]}
                        selected={washReservationState}
                        setSelected={setWashReservationState}
                        width={200}
                        widthSelect={200}
                        tabIndex={2}
                        reset={[]}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              {washReservationState !== null ? (
                washReservationState.name === "Το πλύσιμο ξεκινάει" ? (
                  <>
                    <tr>
                      <td className="modal-field-title">
                        Το πλύσιμο ξεκινάει στις:
                      </td>
                      <td>
                        <>
                          <input
                            type="datetime-local"
                            className={
                              "my-input m-0 w-200px " +
                              (startsAtError ? "error-selected" : "")
                            }
                            value={startsAt}
                            onChange={(e) => setStartsAt(e.target.value)}
                          />
                          {startsAtError !== false ? (
                            <p className="error-p">
                              Η επιλογή είναι υποχρεωτική
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      </td>
                    </tr>
                  </>
                ) : washReservationState.name === "Το πλύσιμο ολοκληρώθηκε" ? (
                  <>
                    <tr>
                      <td className="modal-field-title">
                        Το πλύσιμο ολοκληρώθηκε στις:
                      </td>
                      <td>
                        <>
                          <input
                            type="datetime-local"
                            className={
                              "my-input m-0 w-200px " +
                              (endsAtError ? "error-selected" : "")
                            }
                            value={endsAt}
                            onChange={(e) => setEndsAt(e.target.value)}
                          />
                          {endsAtError !== false ? (
                            <p className="error-p">
                              Η επιλογή είναι υποχρεωτική
                            </p>
                          ) : (
                            <></>
                          )}
                        </>
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer className="modal-footer">
        {loading ? (
          <ReactLoading type="spin" color="#202020" height={40} width={40} />
        ) : (
          <>
            <button className="cancel-modal-button" onClick={handleClose}>
              Άκυρο
            </button>
            {washReservationState !== null ? (
              <button
                className={
                  "submit-modal-button " +
                  (washReservationState.name === "Ακύρωση πλυσίματος"
                    ? "delete-modal-button"
                    : "")
                }
                onClick={handleSubmit}
              >
                {washReservationState.name === "Το πλύσιμο ξεκινάει"
                  ? "Ανανέωση"
                  : washReservationState.name === "Ακύρωση πλυσίματος"
                  ? "Ακύρωση πλυσίματος"
                  : washReservationState.name === "Το πλύσιμο ολοκληρώθηκε"
                  ? "Ολοκλήρωση πλυσίματος"
                  : "Κάτι πάει λάθος"}
              </button>
            ) : (
              <></>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUpdateReservation;
