import { Link } from "react-router-dom";
import useTitle from "../hooks/useTitle";

const Page404 = ({ title }) => {
  useTitle({ title });

  return (
    <>
      <div className="content">
        <section className="page-404 flex-column-center-center">
          <h2>404 .... Κάτι λάθος έκανες</h2>
          <h3>
            Η σελίδα δεν υπάρχει... <br /> Πάτα{" "}
            <Link to="/">εδώ για να επιστρέψεις στην αρχική</Link>.
          </h3>
        </section>
      </div>
    </>
  );
};

export default Page404;
