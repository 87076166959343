import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";

import AuthProvider from "../context/AuthProvider";

const RequireAuth = () => {
  const [authenticated, setAuthenticated] = useState("loading");
  const alert = useAlert();

  const location = useLocation();

  useEffect(() => {
    const getData = async () => {
      const { isAuthenticated } = AuthProvider();
      const checkAuth = await isAuthenticated();

      if (checkAuth) setAuthenticated("authenticated");
      else {
        alert.info("Συνδεθείτε για να συνεχίσετε");
        setAuthenticated("not-authenticated");
      }
    };

    getData();
  }, [alert, authenticated]);

  return authenticated === "loading" ? (
    <div className="loading-big fccc">
      <ReactLoading type="spin" color="#202020" height={80} width={80} />
    </div>
  ) : authenticated === "authenticated" ? (
    <Outlet />
  ) : authenticated === "not-authenticated" ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : null;
};

export default RequireAuth;
