import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

import Layout from "./components/Layout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Page404 from "./pages/Page404";
import RequireAuth from "./components/RequireAuth";
import AlertTemplate from "./components/AlertTemplate";
import Logout from "./pages/Logout";

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

function App() {
  const [station, setStation] = useState({ name: "", id: null });

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Routes>
        {/* public routes */}
        <Route element={<Layout station={station} />}>
          <Route
            path="/login"
            element={<Login title="Είσοδος πρατηρίου - Καύσιμα Μπαράτσας" />}
          />
        </Route>

        {/* we want to protect these routes */}
        <Route element={<RequireAuth />}>
          <Route element={<Layout station={station} />}>
            <Route
              path="/"
              element={
                <Dashboard
                  title="Κρατήσεις - Καύσιμα Μπαράτσας"
                  station={station}
                  setStation={setStation}
                />
              }
            />
          </Route>

          <Route element={<Layout station={station} />}>
            <Route
              path="/logout"
              element={<Logout title="Log out - Καύσιμα Μπαράτσας" />}
            />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Page404 title="404 - Δεν το βρήκα..." />} />
      </Routes>
    </AlertProvider>
  );
}

export default App;
