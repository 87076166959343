import { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import AuthProvider from "../context/AuthProvider";
import useTitle from "../hooks/useTitle";
import { loginUser } from "../api/backendCalls";
import CSRFToken from "../api/csrftoken";

const Login = ({ title }) => {
  useTitle({ title });

  const { setAuth } = AuthProvider();

  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    const from = "/";

    const doAsync = async () => {
      const { isAuthenticated } = AuthProvider();
      const checkAuth = await isAuthenticated();

      if (checkAuth) navigate(from, { replace: true });
    };

    doAsync();
  }, [loggedIn, navigate, location]);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let csrfToken = document.getElementById("csrfmiddlewaretoken-some-id");

    let [error, accessToken] = await loginUser(user, pwd, csrfToken.value);
    if (error) {
      setErrMsg(error);
      errRef.current.focus();
      return;
    }

    setAuth({ accessToken });
    setUser("");
    setPwd("");
    setLoggedIn(true);
  };

  return (
    <section className="signin-page fccc">
      <div className="first-div fccc">
        <div className="second-div fccc">
          <div className="third-div fccc">
            <p ref={errRef} className={errMsg ? "errmsg" : "no-errmsg"}>
              {errMsg}
            </p>
            <h1>Σύνδεση</h1>
            <form onSubmit={handleSubmit}>
              <CSRFToken />

              <input
                placeholder="Username"
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />

              <input
                placeholder="Password"
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
              />
              <button>Σύνδεση</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
